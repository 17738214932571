import type {
  BroadcastClientEventMap,
  BroadcastServerEventMap,
  ClientBroadcastNotificaton,
} from '@snapchat/mw-common';
import { DeploymentType } from '@snapchat/mw-common';
import { useEffect } from 'react';
import type { Socket } from 'socket.io-client';
import { io as createSocket } from 'socket.io-client';

import { Config } from '../../config';
import { logError, logInfo, logWarning } from '../../helpers/logging';
import { handleSecproxySiteError } from '../../helpers/sockets/handleSecproxySiteError';

export const webPlatformHostname = Config.isDeploymentTypeProd
  ? 'https://web-platform.snap.com'
  : 'https://auto-dot-broadcast-service-dot-entapps-web-dev.gae.sc-corp.net';

// Singleton. We want to maintain only a single socket connection.
let socket: Socket<BroadcastServerEventMap, BroadcastClientEventMap> | undefined;
let components = 0;
const subscriptions = new Map<string, number>();

/** Creates the socket instance. */
function ensureSocket(): Socket<BroadcastServerEventMap, BroadcastClientEventMap> {
  if (socket) {
    return socket;
  }

  socket = createSocket(webPlatformHostname, {
    path: '/messaging/socket',
    upgrade: false,
    transports: ['websocket'], // Forces websockets.
    withCredentials: Config.deploymentType !== DeploymentType.PRODUCTION,
    reconnectionAttempts: 3,
    rejectUnauthorized: false,
    timeout: 5_000,
  });

  return socket;
}

/**
 * Hook for adding listeners to the messaging socket connection.
 *
 * The {@param eventKey} is used to identify what needs to be listened to.
 *
 * It's okay to use multiple useBroadcast hooks in the same component (even for the same key)
 */
export function useBroadcast(
  eventKey: string | undefined,
  listeners: {
    onMessage: (message: ClientBroadcastNotificaton) => void;
    onConnect?: () => void;
    onError?: (error: Error) => void;
    // Note: The description type is robust but is not exported. See DisconnectDescription.
    onDisconnect?: (reason: Socket.DisconnectReason, description?: unknown) => void;
    onReconnect?: () => void;
  }
): void {
  const { onMessage, onConnect, onError, onDisconnect, onReconnect } = listeners;

  useEffect(() => {
    if (!eventKey) return;

    // Creates a socket if one doesn't exist.
    ensureSocket();
    components++;

    socket?.on(eventKey, onMessage);

    const onErrorInternal = (error: Error) => {
      logError({
        component: 'useBroadcast',
        error,
        context: { eventKey },
      });

      onError?.(error);

      handleSecproxySiteError(error, webPlatformHostname);
    };

    // See https://socket.io/docs/v4/client-api/#event-disconnect
    const onDisconnectInternal = (reason: Socket.DisconnectReason) => {
      logWarning({
        component: 'useBroadcast',
        message: 'Socket Disconnected',
        context: { eventKey },
      });

      onDisconnect?.(reason);
    };

    // See https://socket.io/docs/v4/client-api/#event-reconnect
    const onReconnectInternal = () => {
      logInfo({
        eventCategory: 'useBroadcast',
        eventAction: 'reconnected',
        eventLabel: eventKey,
      });

      onReconnect?.();
    };

    const onConnectInternal = () => {
      logInfo({
        eventCategory: 'useBroadcast',
        eventAction: 'connect',
        eventLabel: eventKey,
      });

      onConnect?.();

      // Subscribe to notifications on each connect event (in case of disconnect)
      // Subscribing twice is fine, the server will handle it without issue.
      socket?.emit('subscribe', { eventKey });
      subscriptions.set(eventKey, (subscriptions.get(eventKey) ?? 0) + 1);

      logInfo({
        eventCategory: 'useBroadcast',
        eventAction: 'subscribe',
        eventLabel: eventKey,
      });
    };

    socket?.connected && onConnectInternal();

    socket?.on('connect', onConnectInternal);
    socket?.on('connect_error', onErrorInternal);
    socket?.on('disconnect', onDisconnectInternal);
    socket?.io?.on('reconnect', onReconnectInternal);

    // On destroy we unsubscribe so the socket can remain open but no events are sent
    // sence noone is listening.
    return () => {
      subscriptions.set(eventKey, subscriptions.get(eventKey)! - 1);

      // We unsubscribe if there are no more listeners on the eventKey.
      setTimeout(() => {
        socket?.connected &&
          subscriptions.get(eventKey) === 0 &&
          socket?.emit('unsubscribe', { eventKey });
      }, 3e3);

      socket?.off('connect', onConnectInternal);
      socket?.off('connect_error', onErrorInternal);
      socket?.off('disconnect', onDisconnectInternal);
      socket?.io?.on('reconnect', onReconnectInternal);
      socket?.off(eventKey, onMessage);

      components--;

      // Maybe close the socket if no new components uses broadcast (and allow garbage collection)
      setTimeout(() => {
        if (components) return;
        socket?.close();
        socket = undefined;
      }, 5e3);
    };
  }, [eventKey, onConnect, onError, onMessage, onDisconnect, onReconnect]);
}
