import { cx } from '@emotion/css';
import { useWindowSize } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect, useRef } from 'react';

import type { BitmojiProps } from '../BitmojiControls/types';
import type { BitmojiStreamItem } from '../BitmojiProvider';
import { BitmojiContext, invalidImgUrl } from '../BitmojiProvider';
import { bitmojiCanvasCss } from './bitmojiCanvas.styled';
import {
  bitmojiCanvasWidthPixels,
  defaultBitmojiCanvasHeight,
  defaultBitmojiCanvasScale,
} from './constants';

export const BitmojiCanvas: FC<BitmojiProps> = ({ enableBitmoji = true, className }) => {
  const animationIdRef = useRef(0);
  const previousTimestampMs = useRef(0);

  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { bitmojiStreamRef, canvasRef } = useContext(BitmojiContext);

  const dpr = window.devicePixelRatio || defaultBitmojiCanvasScale;

  const drawBitmoji = (item: BitmojiStreamItem) => {
    // Opacity is a function of how close to the top of the canvas
    // the bitmoji is (i.e. how close y coordinate is to 0).
    // Only start fading bitmoji in the top quarter of the canvas.
    const canvasHeight = canvasRef?.current?.height ?? defaultBitmojiCanvasHeight;
    const context = canvasRef?.current?.getContext('2d');
    const opacity = item.y / (canvasHeight / 4);

    if (!context || item.image.src.endsWith(invalidImgUrl)) return;

    context.globalAlpha = Math.max(0, opacity);

    // draw image at current position.
    // NOTE: Drawing the exact size of the loaded image. This forces the image to be drawn at its original size
    // without scaling because canvas scaling is *terrible* and produces blurry images unless it's pixel perfect.
    context.drawImage(item.image, item.x, item.y, item.image.width, item.image.height);
  };

  const animate = (timestampMs: number) => {
    const deltaMs = timestampMs - previousTimestampMs.current;
    previousTimestampMs.current = timestampMs;

    const canvas = canvasRef?.current;
    const bitmojiStream = bitmojiStreamRef?.current;

    if (!canvas || !bitmojiStream) return;

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0); // clear canvas
    ctx.imageSmoothingEnabled = false; // Disable image smoothing for pixel-perfect rendering

    // Transform the coordinates of existing stream

    // Bitmoji should take 4 seconds (i.e 4000 ms) to travel the height of the canvas
    const bitmojiDistancePerMs = canvas.height / 4000;

    // Calculate the distance to cover in this frame
    const distanceToCover = bitmojiDistancePerMs * deltaMs;

    const newStream = bitmojiStream
      .map(({ image, x, y }) => {
        const newY: number = y - distanceToCover;
        return { image, x, y: newY };
      })
      .filter(({ y }) => y > 0);

    // Draw the bitmoji stream in new positions
    newStream?.forEach(bitmoji => drawBitmoji(bitmoji));

    // Update the bitmoji stream with new positinos
    bitmojiStreamRef.current = newStream;

    // Update canvas context and animation ref
    ctx.globalAlpha = 1;
    animationIdRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (canvasRef?.current) {
      animate(0);
    }

    // Need to cancel animation loop when we no longer have a canvas.
    return () => cancelAnimationFrame(animationIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!canvasRef?.current) return;

    canvasRef.current.height = windowHeight ? dpr * windowHeight : defaultBitmojiCanvasHeight;
    canvasRef.current.width = dpr * bitmojiCanvasWidthPixels;
  }, [windowWidth, windowHeight, canvasRef, dpr]);

  if (!enableBitmoji) {
    return null;
  }

  return (
    <canvas
      id="bitmoji-reaction-canvas"
      className={cx(bitmojiCanvasCss, className)}
      ref={canvasRef}
    />
  );
};
