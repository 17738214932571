import { cx } from '@emotion/css';
import type {
  ClientBroadcastNotificaton,
  ReactionNotification,
  ReactionSubmitPayload,
} from '@snapchat/mw-common';
import {
  getBitmojiReactionEventKey,
  getLocalStorageItem,
  setLocalStorageItem,
} from '@snapchat/mw-common';
import { Button, FormattedMessage, MessageContext } from '@snapchat/snap-design-system-marketing';
import throttle from 'lodash-es/throttle';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { Socket } from 'socket.io-client';

import { AppContext } from '../../../../AppContext';
import { Config } from '../../../../config';
import { UrlParameter } from '../../../../constants/urlParameters';
import { logInfo, logTiming } from '../../../../helpers/logging';
import { useBroadcast } from '../../../../hooks/useBroadcast';
import { useBitmojiVideoSync } from '../../hooks/useBitmojiReplays';
import { BitmojiContext } from '../BitmojiProvider';
import { bitmojiReactionsCss, bitmojiReactionsNoBitmojiTextCss } from './BitmojiControls.styles';
import type { BitmojiControlsProps } from './BitmojiControls.types';
import { BitmoReactionToggle } from './BitmojiReactionToggle';
import { getBitmojiReactionSocket } from './getBitmojiReactionSocket';
import { ReactionButton } from './ReactionButton';
import { SnapKitLoginButton } from './SnapKitLoginButton';

/** List of reactions we allow users to select. */
const reactionIds = ['20082396', '20082398', '20082395', '20082657', '20082661'];

const devBitmojiId = 'eqsURrjjNqYsDB48JvMF_lfS5Cv240zqvlFlTEg38wIge7BzP5JqTg'; // Example Bitmoji ID for development purposes
const devReactionId = '20082396';

// Singleton that is managed by component, only creates when logged in
let reactionSocket: Socket | undefined;

const isReactionTimeInVideoWindow = (currentVideoTime?: number, reactionVideoTimeUtc?: number) => {
  if (currentVideoTime === undefined || reactionVideoTimeUtc === undefined) return false;

  // Allow people to see reactions from earlier in real time, while later are synced at the right time
  return (
    currentVideoTime - 7e3 < reactionVideoTimeUtc && reactionVideoTimeUtc < currentVideoTime + 1e3
  );
};

export const BitmojiControls: FC<BitmojiControlsProps> = ({
  hideBitmoji,
  hideBitmojiClass,
  setHideBitmoji,
  videoTimestampRef,
  bitmojiProps,
  analyticsId,
  buttonsDisabled,
}) => {
  const [attemptedCredentialRetrieval, setAttemptedCredentialRetrieval] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [avatarId, setAvatarId] = useState('');
  const { sendToBitmojiStream } = useContext(BitmojiContext);
  const { getCurrentUrl } = useContext(AppContext);

  const { formatMessage } = useContext(MessageContext);

  const url = new URL(getCurrentUrl());
  const disableWebSockets =
    url.searchParams.get(UrlParameter.EXPERIENCE_DISABLE_WEB_SOCKETS) === 'true';

  // use ref for needed values that shouldn't be redone on renders
  const accessTokenRef = useRef('');
  const avatarIdRef = useRef('');

  // If we have an access token but no avatar id, that signifies the user has no bitmoji avatar
  // set up for their account
  const hasNoBitmojiAvatar = !avatarId && accessToken && attemptedCredentialRetrieval;

  const setCredentials = useCallback(
    (bitmojiId: string | undefined, snapToken: string) => {
      // Set the access token
      setLocalStorageItem('mwp-snapkit-access-token', snapToken);
      setAccessToken(snapToken);
      accessTokenRef.current = snapToken;

      // Snapchat accounts without bitmoji avatars will not have a bitmoji id
      if (!bitmojiId) return;

      setLocalStorageItem('mwp-snapkit-user-id', bitmojiId);
      setAvatarId(bitmojiId);
      avatarIdRef.current = bitmojiId;
    },
    [setAvatarId, setAccessToken]
  );

  // inital credentials setup and listening for visibility
  useEffect(() => {
    const localAvatarId = getLocalStorageItem('mwp-snapkit-user-id') ?? '';
    const localAccessToken = getLocalStorageItem('mwp-snapkit-access-token') ?? '';

    if (localAccessToken) {
      setCredentials(localAvatarId, localAccessToken);
    }

    // Flag that we've attempted to query local storage for a previous SnapKit login so we know if
    // we should show the login button or not
    setAttemptedCredentialRetrieval(true);
  }, [setCredentials]);

  // Log if user has no bitmoji avatar
  useEffect(() => {
    if (hasNoBitmojiAvatar) {
      logInfo({
        eventAction: 'Authenticated',
        eventCategory: 'BitmojiControls',
        eventLabel: 'noBitmojiAvatar',
      });
    }
  }, [hasNoBitmojiAvatar]);

  // ==========================================================================
  // Receiving reactions from the broadcast service
  // ==========================================================================

  // Need to keep live reactions in sync with the video since user can be 10+ seconds behind others.
  // Send any reactions that are out of our video view window to be played later.
  const { syncBitmojiReactions } = useBitmojiVideoSync(videoTimestampRef, bitmojiProps);

  const onBitmojiReaction = useCallback(
    (notification: ClientBroadcastNotificaton) => {
      const reaction = notification as ReactionNotification;
      const { bitmojiId, bitmojiReactionType, clientTimestamp } = reaction;

      clientTimestamp &&
        bitmojiId === avatarIdRef.current &&
        logTiming({
          eventVariable: 'reaction_total_duration',
          eventValue: Date.now() - new Date(clientTimestamp).getTime(),
          eventCategory: 'EventLogin',
        });

      if (bitmojiId === avatarIdRef.current) {
        return; // if the user submitted this bitmoji no need to render during live at all.
      }

      if (!sendToBitmojiStream) return;

      if (isReactionTimeInVideoWindow(videoTimestampRef.current, reaction.videoTime)) {
        // draw bitmoji immediately if its within the time window, else let the useBitmojiVideoSync handle it
        sendToBitmojiStream(bitmojiReactionType, bitmojiId);
      } else {
        syncBitmojiReactions([reaction]);
      }
    },
    [syncBitmojiReactions, sendToBitmojiStream, videoTimestampRef]
  );

  const reactionVideoEventKey = getBitmojiReactionEventKey(bitmojiProps.videoId);

  useBroadcast(disableWebSockets ? undefined : reactionVideoEventKey, {
    onMessage: onBitmojiReaction,
  });

  // ==========================================================================
  // Submitting reactions if the user is logged in
  // ==========================================================================

  // update reaction socket if certain settings change
  useEffect(() => {
    if (!accessToken || disableWebSockets) return;

    reactionSocket = getBitmojiReactionSocket({
      accessToken,
      onExpiredCredentials: () => setCredentials('', ''),
    });

    // destroy socket when credentials update
    return () => {
      reactionSocket?.close();
      reactionSocket = undefined;
    };
  }, [accessToken, disableWebSockets, setCredentials]);

  // Throttles any emissions to be one per second.
  const sendToBrs = useMemo(
    () =>
      throttle((reactionRequest: ReactionSubmitPayload) => {
        reactionSocket?.emit('submitReaction', reactionRequest);
      }, 1000),
    []
  );

  // ==========================================================================
  // Render Logic
  // ==========================================================================

  const bitmojiSignInMessage = formatMessage({
    id: 'bitmojiSignIn',
    defaultMessage: 'Sign in to use Bitmoji',
  });

  const bitmojiRetryMessage = formatMessage({
    id: 'bitmojiRetry',
    defaultMessage: 'Retry',
  });

  const snapkitLoggedIn = accessToken ? 'snapkit-logged-in' : '';

  const renderControls = () => {
    if (!attemptedCredentialRetrieval) return null;

    if (!accessToken) {
      return (
        <SnapKitLoginButton buttonText={bitmojiSignInMessage} onAuthenticated={setCredentials} />
      );
    }

    if (hasNoBitmojiAvatar) {
      return (
        <>
          <p className={bitmojiReactionsNoBitmojiTextCss}>
            <FormattedMessage
              id="accountHasNoBitmoji"
              defaultMessage="Set up your Bitmoji avatar in the Snapchat app to send Bitmoji reactions!"
            />
          </p>
          <SnapKitLoginButton buttonText={bitmojiRetryMessage} onAuthenticated={setCredentials} />
        </>
      );
    }

    return reactionIds.map(reactionId => (
      <ReactionButton
        key={reactionId}
        reactionId={reactionId}
        avatarId={avatarId}
        videoTimestampRef={videoTimestampRef}
        sendToBrs={sendToBrs}
        videoId={bitmojiProps.videoId}
        analyticsId={analyticsId}
        isDisabled={buttonsDisabled}
      />
    ));
  };

  return (
    <section className={cx(snapkitLoggedIn, hideBitmoji, hideBitmojiClass)}>
      <article className={bitmojiReactionsCss}>
        <BitmoReactionToggle
          hideBitmoji={hideBitmoji}
          avatarId={avatarId}
          setHideBitmoji={setHideBitmoji}
        />
        {renderControls()}
        {Config.isLocal && (
          <Button
            aria-description="On localhost, bitmojis don't work; use this button to send a reaction."
            onClick={() => sendToBitmojiStream?.(devReactionId, devBitmojiId)}
          >
            React (Dev)
          </Button>
        )}
      </article>
    </section>
  );
};
