import { css } from '@emotion/css';

export const toastOpacityTransitionTimeMs = 250;

export const toastContainerCss = css`
  position: absolute;
  /* NOTE: If you need to position this somewhere other than top right corner,
   * The caller can pass in a custom className with overrides. */
  top: 0;
  right: 0;

  /* Target width is all viewport - 2xMargin */
  width: calc(100vw - 32px);
  max-width: 384px;
  min-height: 50px;

  margin: 16px;
  /* Extra padding on the right for the close button. */
  padding: 16px 48px 16px 16px;

  border-radius: 16px;
  background-color: white;
  border: 1px solid #f0f1f2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  /* TODO: Read header z-index from SDS-M */
  z-index: 101; /* Bigger than the header */

  transition: opacity ${toastOpacityTransitionTimeMs}ms;

  /* Toast should not prevent clicking on elements under itself unless it is open */
  pointer-events: none;

  opacity: 0;
  &.open {
    /* We have an 'open' state that we trigger AFTER the component loads
     * so that we can animation the transition */
    transition-timing-function: easy-in;
    opacity: 1;
    pointer-events: unset;
  }
  &.closed {
    opacity: 0;
    transition-timing-function: easy-out;
  }
  &.hidden {
    display: none;
  }
`;

// TODO: If we port this into SDS-M it needs RTL support.
export const toastCloseButtonCss = css`
  position: absolute;
  right: 11px;
  top: 11px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #858d94;
  background-color: white;
  cursor: pointer;
`;

export const toastCloseBorderCss = css`
  /* Degree controlled through JS. */
  --degree: 360deg;
  position: absolute;
  right: 9px;
  top: 9px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* background: conic-gradient(#858d94 var(--degree), transparent var(--degree) 100%); */
  background: conic-gradient(transparent var(--degree), #858d94 var(--degree) 100%);
`;

export const toastCloseIconCss = css`
  height: 8px;
  margin-bottom: 1px;
`;
