import { gql } from '@apollo/client';
import type { FormDataProps } from 'src/components/Form';

import { ctaFragment } from '../../../../components/CallToAction/query';
import type { CallToActionDataProps } from '../../../../components/CallToAction/types';
import { formFragments } from '../../../../components/Form';
import type { ImageDataProps } from '../../../../components/Image';
import { fragments as imageFragments } from '../../../../components/Image';
import { assetFragment } from '../../../../components/Media';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import type { RichText } from '../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const sps2024ConfirmationFragment = gql`
  fragment Sps2024ConfirmationFragment on ExperienceSps2024Confirmation {
    animationOverrideImage {
      ...ImageAll
    }
    personalizedTitle
    fallbackTitle
    topText {
      json
    }
    middleText {
      json
    }
    bottomText {
      json
    }
    personalizedAnimationTextLines
    fallbackAnimationTextLines
    showSaveTheDate
    googleCalendarEventUrl
    icsFile {
      ...AssetAll
    }
    usernameFormText {
      json
    }
    usernameForm {
      ... on Form {
        ...FormAll
      }
    }
    callToAction {
      ... on CallToAction {
        ...CallToActionAll
      }
    }
    registrationSource {
      ... on ExperienceSps2024Registration {
        ...ContentfulSysId
      }
    }
    ...ContentfulSysId
  }
  ${imageFragments.all}
  ${formFragments.all}
  ${assetFragment}
  ${ctaFragment}
  ${contentfulSysIdFragment}
`;

export interface Sps2024ConfirmationProps
  extends ContentfulTypedSysProps<'ExperienceSps2024Confirmation'> {
  animationOverrideImage?: ImageDataProps;
  personalizedTitle?: string;
  fallbackTitle?: string;
  topText?: RichText;
  middleText?: RichText;
  bottomText?: RichText;
  personalizedAnimationTextLines?: string[];
  fallbackAnimationTextLines?: string[];
  showSaveTheDate?: boolean;
  googleCalendarEventUrl?: string;
  icsFile?: { url: string };
  usernameFormText?: RichText;
  usernameForm?: FormDataProps;
  callToAction?: CallToActionDataProps;
  registrationSource?: ContentfulTypedSysProps<'ExperienceSps2024Registration'>;
}

export const sps2024ConfirmationQuery = gql`
  query Sps2024Confirmation($id: String!, $locale: String!, $preview: Boolean!) {
    experienceSps2024Confirmation(id: $id, locale: $locale, preview: $preview) {
      ...Sps2024ConfirmationFragment
    }
  }
  ${sps2024ConfirmationFragment}
`;

export interface Sps2024ConfirmationQueryData {
  experienceSps2024Confirmation: Sps2024ConfirmationProps;
}
