import { cx } from '@emotion/css';
import { BackgroundColor, getBackgroundClassName } from '@snapchat/snap-design-system-marketing';
import cloneDeep from 'lodash-es/cloneDeep';
import type { FC } from 'react';

import { CallToAction } from '../../../../components/CallToAction';
import type { CallToActionDataProps } from '../../../../components/CallToAction/types';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import {
  highlightButtonsCss,
  highlightContentCss,
  highlightContentDescriptionCss,
  highlightContentTitleCss,
  highlightCss,
} from '../Highlight/Highlight.styled';
import { VideoPreview } from '../VideoPreview/VideoPreview';
import type { HighlightProps } from './Highlight.types';

export const Highlight: FC<HighlightProps> = ({
  title,
  blurb,
  videoUrl = '',
  callToActionsCollection,
  videoLength,
  videoBackgroundImage,
  captionsCollection,
  aslUrl,
  bitmojiProps,
  analyticsId,
}) => {
  const ctas = callToActionsCollection?.items.map(cta => {
    const clonedCta = cloneDeep(cta as CallToActionDataProps);
    if (!clonedCta.presentation) return null;

    const sansHttpPrefix = clonedCta.presentation.url?.replace('http://', '') ?? '';

    clonedCta.presentation.url = sansHttpPrefix;

    if (clonedCta.analytics) {
      clonedCta.analytics.label = `${title ?? ''}-${clonedCta.analytics.label}`;
    }

    return <CallToAction key={clonedCta.sys.id} {...clonedCta} />;
  });

  return (
    <article className={highlightCss}>
      <VideoPreview
        videoTitle={title}
        videoUrl={videoUrl}
        videoLength={videoLength}
        videoBackgroundImage={videoBackgroundImage}
        captionsCollection={captionsCollection}
        aslUrl={aslUrl}
        bitmojiProps={bitmojiProps}
        analyticsId={analyticsId}
      />
      <section className={cx(highlightContentCss, getBackgroundClassName(BackgroundColor.White))}>
        <h3 className={highlightContentTitleCss}>{title}</h3>
        <p className={highlightContentDescriptionCss}>{renderRichText(blurb ?? '')}</p>
        <div className={highlightButtonsCss}>{ctas}</div>
      </section>
    </article>
  );
};
