import { css } from '@emotion/css';
import {
  largeDesktopMediaQuery,
  mobileMediaQuery,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const spsLogoCharCountCssVar = '--sps-logo-char-count';
export const usernameFormTextClassName = 'username-form-text';

// Registration state styles

export const formOverrideCss = css`
  form {
    align-items: stretch;
    justify-content: flex-start;
    text-align: start;
    max-width: 510px;
    margin: unset;

    /* default text row items smaller than normal */
    p {
      font-size: 16px;
    }
    /* smaller font for checkbox text */
    label[for='Email_Opt_In__c'] > * {
      font-size: 14px;
    }
  }

  form > :nth-last-child(2) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const registrationFormCss = css`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  min-height: calc(100vh - var(--total-header-height));
  min-height: calc(100dvh - var(--total-header-height));

  ${mobileMediaQuery} {
    min-height: unset;
  }

  ${largeDesktopMediaQuery} {
    padding-left: 1vw;
  }

  .closed-text {
    line-height: 28px;
  }

  h5 {
    padding-bottom: 20px;
  }

  .error {
    color: #e93939;
  }

  /* override form alignment and other styling */
  ${formOverrideCss}
`;

export const registrationEventCss = css`
  min-height: 70px;
  line-height: 50px;

  p {
    font-size: 20px;
  }

  ${nonMobileMediaQuery} {
    text-align: center;
  }

  /* To render above the logo animation background */
  z-index: 1;
`;

export const registrationEventContainerCss = css`
  max-height: calc(100vh - var(--total-header-height));
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-evenly;

  max-width: 600px;

  ${mobileMediaQuery} {
    padding-top: var(--total-header-height);
    padding-left: unset;
  }
`;

export const animationOverrideCss = css`
  ${nonMobileMediaQuery} {
    /* To position animation closer it closer to the top */
    top: -136px;
  }
  top: -51px;
`;

export const registrationContainerCss = css`
  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }

  /* Use a negative margin so the container will overlap with the transparent header */
  margin-top: calc(var(--total-header-height) * -1);

  /* Hides the scrollbar in the middle of the split block panel.
   * Hack so we can avoid modifying SDS-M on a strict timeline. */
  &&& > * > * {
    overflow: hidden;
    padding: 32px;
  }
`;
