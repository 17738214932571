import {
  defaultMotif,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
} from '@snapchat/snap-design-system-marketing';

const transparentHeaderOverrides = {
  [MotifComponent.HEADER]: {
    '--global-header-bg-color': 'transparent',
  },
  // Hide loading bar (visible loading bar clashes with SPS branding)
  [MotifComponent.LOADING_BAR]: {
    '--loading-bar-left-color': 'transparent',
    '--loading-bar-right-color': 'transparent',
  },
};

export const experienceMotif = mergeMotifs(defaultMotif, {
  name: 'Experience Motif',
  [MotifScheme.DEFAULT]: {
    name: 'Default with transparent header and transparent loading bar',
    ...transparentHeaderOverrides,
  },
  [MotifScheme.SECONDARY]: {
    name: 'Secondary with transparent header and transparent loading bar',
    ...transparentHeaderOverrides,
  },
  [MotifScheme.TERTIARY]: {
    name: 'Tertiary with transparent header and transparent loading bar',
    ...transparentHeaderOverrides,
  },
  [MotifScheme.QUATERNARY]: {
    name: 'Quaternary with transparent header and transparent loading bar',
    ...transparentHeaderOverrides,
  },
});
