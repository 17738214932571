import type { FC, PropsWithChildren } from 'react';
import { useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { UrlParameter } from '../../constants/urlParameters';
import { debugPortalCss } from './DebugPortal.styles';

const debugPortalId = 'mwp-debug'; // Used in index.ejs

/**
 * Easy insertion point into the DebugPortal.
 *
 * Use as <IntoDebugPortal>{.. your stuff ..}</IntoDebugPortal>
 *
 * NOTE: The content only gets inserted on the client. Nothing displays on the server.
 */
export const IntoDebugPortal: FC<{ name: string } & PropsWithChildren> = ({ children, name }) => {
  const debugPortal = Config.isClient ? document.getElementById(debugPortalId) : null;

  const { getCurrentUrl } = useContext(AppContext);
  const currentUrl = new URL(getCurrentUrl());
  const isEnabled = currentUrl.searchParams.has(UrlParameter.MWP_DEBUG);

  useEffect(() => {
    if (!isEnabled) return;
    debugPortal?.classList.add(debugPortalCss);
  }, [debugPortal, isEnabled]);

  if (!isEnabled || !debugPortal) {
    return null;
  }

  return createPortal(children, debugPortal, name);
};
IntoDebugPortal.displayName = 'IntoDebugPortal';
