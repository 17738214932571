import { corpFetch } from '@snapchat/secproxy-utils-browser';
import noop from 'lodash-es/noop';

import { Config } from '../../config';

// Only to be used by dev and staging, due to secproxy needing authorization. We fetch the url in a iframe to get the cookies.
export const handleSecproxySiteError = (error: Error, url: string): void => {
  // skip if prod, or is not a websocket error or we already opened the tab
  if (Config.isDeploymentTypeProd || error.message !== 'websocket error') {
    return;
  }

  // This is expected to fail (there's nothing on that endpoint).
  void corpFetch(url).catch(noop);
};
