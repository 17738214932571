import { ensureSingleScript } from '@snapchat/mw-common/client';
import noop from 'lodash-es/noop';
import type { FC } from 'react';
import { memo, useCallback, useEffect } from 'react';

import { Config } from '../../../../config';
import { logError, logInfo, logUserEvent } from '../../../../helpers/logging/loggingInstance';
import { UserAction } from '../../../../types/events';
import type { AuthResponse } from '../../../../types/loginKit';
import { loginButtonCss } from './SnapKitLoginButton.styles';

const loginKitMountId = 'experience-login-kit-mount';
const loginKitScopeList = ['user.bitmoji.avatar'];

// https://kit.snapchat.com/manage/apps/57d2e42b-f6e3-4cea-be51-654c4837f302
const loginKitClientIds = {
  preview: 'b083a05b-c423-40f6-b802-35324fc704dc', // staging Public OAuth 2.0 Client ID
  production: '5d855372-c022-40c4-bd2f-32593a60a473', // production Public OAuth 2.0 Client ID
};
const loginKitClientId = !Config.isDeploymentTypeProd
  ? loginKitClientIds.preview
  : loginKitClientIds.production;

const defaultButtonText = 'Sign in to use Bitmoji';

export interface LoginProps {
  onAuthenticated: (userId: string | undefined, authToken: string) => void;
  buttonText?: string;
}

const InternalSnapKitLoginButton: FC<LoginProps> = props => {
  /** Function to call the callback after a user has logged in. */
  const handleLogin = useCallback(
    async (response: AuthResponse): Promise<void> => {
      if (response.errorMessage) {
        logError({ component: 'SnapKitLoginButton', error: response.errorMessage });
        return;
      }
      const userInfo = await window.snap!.loginkit!.fetchUserInfo();

      if (userInfo.errorMessage) {
        logError({ component: 'SnapKitLoginButton', error: userInfo.errorMessage });
        return;
      }

      // TODO: The code below is weird and undocumented.
      // We need to refactor it but I (Alex) can't get the login to work
      // on local to inspect the payload. Leaving this as-is for now.
      const splitUrl = userInfo.data?.me.bitmoji.avatar?.split('-');
      const userId = splitUrl?.[splitUrl.length - 2];
      const accessToken = response.access_token!;

      // Log successful user authentication (internal event)
      logInfo({
        eventAction: 'Authenticated',
        eventCategory: 'SnapKitLoginButton',
        eventLabel: 'BitmojiLogin',
      });

      props.onAuthenticated(userId, accessToken);
    },
    [props]
  );

  const initializeButton = useCallback(() => {
    const textContainer = document.querySelector(`#${loginKitMountId} button > span`);
    if (!textContainer) return noop;
    textContainer.innerHTML = props.buttonText ?? defaultButtonText;

    // If we got this far, we know that the button node exists
    const button = document.querySelector(`#${loginKitMountId} button`)!;

    const onClick = () => {
      logUserEvent({
        eventAction: UserAction.Click,
        eventCategory: 'SnapKitLoginButton',
        eventLabel: 'bitmojiLogin',
      });
    };

    button.addEventListener('click', onClick);

    return () => {
      button.removeEventListener('click', onClick);
    };
  }, [props.buttonText]);

  /** Effect to mount and unmount the loginKit button. */
  useEffect(() => {
    // cleanup behavior set within `setTimeout`
    let cleanup = noop;
    // no trailing slash for path. Our application also handles this.
    const path = window.location.pathname.replace(/\/$/, '');

    // NOTE: Delayed because the elements aren't attached immediately after they are created.
    setTimeout(() => {
      ensureSingleScript('login-kit-script', '/third-party/loginKit.js', () => {
        window.snap?.loginkit?.mountButton(loginKitMountId, {
          clientId: loginKitClientId,
          // NOTE: loginKit does not support passing queryParameters forward
          redirectURI: `${window.location.origin}${path}`,
          scopeList: loginKitScopeList,
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handleResponseCallback: handleLogin,
        });

        // set cleanup behavior
        cleanup = initializeButton();
      });
    });

    return () => {
      cleanup();
      window.snap?.loginkit?.unmountButton(loginKitMountId);
    };
  }, [handleLogin, initializeButton]);

  // See: https://github.sc-corp.net/Snapchat/snap-kit-web-sdk/blob/master/snap-connect-js-sdk/src/ui/index.tsx
  return (
    <article
      data-testid="snapkit-login-button-wrapper"
      id={loginKitMountId}
      className={loginButtonCss}
    >
      {/* SnapKit button goes here */}
    </article>
  );
};

export const SnapKitLoginButton = memo(InternalSnapKitLoginButton);
