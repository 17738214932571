import type { ReactionNotification } from '@snapchat/mw-common';
import { VideoTimeFormat } from '@snapchat/mw-common';
import type { MutableRefObject } from 'react';
import { useCallback, useContext, useEffect, useRef } from 'react';

import type { BitmojiProps } from '../../components/BitmojiControls/types';
import { BitmojiContext } from '../../components/BitmojiProvider';

/**
 * Hook to add bitmoji reactions to be played in sync with a video. Can be used for live video or
 * VOD. Adding reactions to the collection, it will render bitmojis based on the video timestamp ref
 * every second.
 *
 * @param videoTimestampRef Ref to a players video timestamp
 * @param bitmojiProps Bitmoji props
 * @returns ReactionCollector (constant) as a map of seconds to reactions. syncBitmojiReactions
 *   function to add reactions to collector to be synced on the videos time.
 *
 *   This function does not cause rerenders on updates to the collector.
 */
export const useBitmojiVideoSync = (
  videoTimestampRef: MutableRefObject<number | undefined>,
  bitmojiProps?: BitmojiProps
): {
  reactionCollector: Map<number, ReactionNotification[]>;
  syncBitmojiReactions: (reactions: ReactionNotification[], timeBucket?: number) => void;
} => {
  const reactionCollector = useRef(new Map() as Map<number, ReactionNotification[]>);
  const { sendToBitmojiStream } = useContext(BitmojiContext);

  useEffect(() => {
    if (!bitmojiProps?.enableBitmoji) {
      return;
    }

    const timeoutIds: NodeJS.Timeout[] = [];

    // Once per second, check reactionCollector for any bitmojis for the current video progress
    let previousSecond = -1;
    const drawReactionsFromCollectionIntervalId = setInterval(() => {
      // Only check cache for new bitmojis if user has made progress in the video
      // (i.e. the video is playing)
      if (
        videoTimestampRef.current === undefined ||
        Math.floor(videoTimestampRef.current / 1000) === previousSecond ||
        !sendToBitmojiStream
      ) {
        return;
      }

      previousSecond = Math.floor(videoTimestampRef.current / 1000);
      const reactions = reactionCollector.current.get(previousSecond);
      const delay = reactions ? 1000 / reactions.length : 0; // spread the reactions over a second

      reactions?.forEach((reaction, i) => {
        timeoutIds.push(
          setTimeout(
            () => sendToBitmojiStream(reaction.bitmojiReactionType, reaction.bitmojiId),
            i * delay
          )
        );
      });
    }, 1000);

    return () => {
      clearInterval(drawReactionsFromCollectionIntervalId);
      timeoutIds.forEach(clearTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bitmojiProps]);

  const syncBitmojiReactions = useCallback(
    (reactions: ReactionNotification[], timeBucket?: number) => {
      // if already have a bucket to use, use it directly. Used by post event videos
      // from the repay api
      if (timeBucket !== undefined) {
        reactionCollector.current.set(timeBucket, reactions);
        return;
      }

      // Used by live stream,
      reactions.forEach(reaction => {
        let timeInSeconds = 0;

        if (reaction.videoTimeFormat && reaction.videoTime !== undefined) {
          if (
            reaction.videoTimeFormat === VideoTimeFormat.SECONDS ||
            reaction.videoTimeFormat === VideoTimeFormat.UTC_SECONDS
          ) {
            timeInSeconds = reaction.videoTime;
          } else {
            timeInSeconds = Math.floor(reaction.videoTime / 1000);
          }
        } else {
          // error !
        }

        if (!reactionCollector.current.has(timeInSeconds)) {
          reactionCollector.current.set(timeInSeconds, []);
        }
        reactionCollector.current.get(timeInSeconds)!.push(reaction);
      });
    },
    [reactionCollector]
  );

  return { reactionCollector: reactionCollector.current, syncBitmojiReactions };
};
