import { getLocalStorageItem } from '@snapchat/mw-common';

import type { RegistrationLocalStorageData } from '../types';

/** Get registration data from local storage */
export const getRegistrationLocalStorageData = (): RegistrationLocalStorageData => {
  const rawLocalStorageData = getLocalStorageItem('experience-registration');
  const parsedLocalStorageData = rawLocalStorageData
    ? (JSON.parse(rawLocalStorageData) as RegistrationLocalStorageData)
    : {};

  return parsedLocalStorageData;
};
