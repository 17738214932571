import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import { Hero } from './Hero';
import { blockHeroQuery } from './query';
import type { HeroDataHandlerProps } from './types';

type HeroShallowProps = ContentfulSysProps;

export const HeroShallow: FC<HeroShallowProps> = props => {
  const { data } = useContentfulQuery<HeroDataHandlerProps, ContentfulIdVariable>(blockHeroQuery, {
    variables: { id: props.sys.id },
  });

  if (!data?.blockHero) return null;

  return <Hero {...data.blockHero} />;
};
