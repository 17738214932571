import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import { Block } from './Block';
import type { BlockDataHandlerProps } from './query';
import { query } from './query';

type BlockShallowProps = ContentfulSysProps;

export const BlockShallow: FC<BlockShallowProps> = props => {
  const { data } = useContentfulQuery<BlockDataHandlerProps, ContentfulIdVariable>(query, {
    variables: { id: props.sys.id },
  });

  if (!data?.block) return null;

  return <Block {...data.block} />;
};
