import type { MutableRefObject } from 'react';
import { useEffect } from 'react';

import type { BitmojiProps } from '../../components/BitmojiControls/types';
import { getReactionReplays } from './getReactionReplays';
import { useBitmojiVideoSync } from './useBitmojiVideoSync';

const oneMinute = 60_000;
const fifteenSeconds = 15_000;

const syncedTimes = new Set<number>(); // keep track of minutes already synced

/**
 * Hook to sync bitmoji reactions from the replay api to a video.
 *
 * @param videoTimestampRef Ref to the video player's timestamp.
 * @param bitmojiProps Canvas props for bitmoji.
 */
export const useBitmojiReplays = (
  videoTimestampRef: MutableRefObject<number | undefined>,
  bitmojiProps?: BitmojiProps
): void => {
  const { syncBitmojiReactions } = useBitmojiVideoSync(videoTimestampRef, bitmojiProps);

  useEffect(() => {
    if (!bitmojiProps?.enableBitmoji || !bitmojiProps?.videoId) {
      return;
    }

    /** Loads replays into the video sync. We avoid calling again on endTimes we've already processed */
    const loadReplays = async (startTime: number, endtime: number) => {
      // we already synced this minute so dont sync again.
      if (syncedTimes.has(endtime)) return;

      const currentReplays = await getReactionReplays(
        bitmojiProps.videoId!,
        startTime.toString(),
        endtime.toString()
      );

      // TODO troubleshoot seeing multiples of this? got more than expected in the playback??
      currentReplays.forEach(bucket => syncBitmojiReactions(bucket.reactions, bucket.videoTime));
      syncedTimes.add(endtime);
    };

    let previousMinute = 0; // keeps track of the minute were in to know if we seeked.

    // Loads replays one minute at a time as we progress through the video. Only loads
    // a minute if we immediately need it or will need it soon.
    const loadNextMinuteReplays = async () => {
      const currentMs = videoTimestampRef.current;

      // Skip if we don't have a start time yet (user hasn't started playing the video).
      if (currentMs === undefined) {
        return;
      }

      const currentMinuteMs = Math.floor(currentMs / oneMinute) * oneMinute;
      const nextMinuteMs = currentMinuteMs + oneMinute;

      // Load current minute if the video just played, we are on a different minute.
      // This also handles when a user seeked.
      if (previousMinute === 0 || currentMinuteMs !== previousMinute) {
        previousMinute = currentMinuteMs;
        await loadReplays(currentMinuteMs, nextMinuteMs);
        return;
      }

      const timeDifference = nextMinuteMs - currentMs;

      // At this point we have already loaded the current minute and haven't seeked.
      // We will only want to load the next minute if we are <= 15 seconds away from the end
      if (timeDifference > fifteenSeconds) {
        return;
      }

      await loadReplays(nextMinuteMs, nextMinuteMs + oneMinute);
    };

    const replayLoadIntervalId = setInterval(loadNextMinuteReplays, 1000);

    return () => {
      clearInterval(replayLoadIntervalId);
    };
  }, [bitmojiProps, syncBitmojiReactions, videoTimestampRef]);
};
