import { cx } from '@emotion/css';
import { useImperativeEffect } from '@snapchat/core-browser';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import { BlockShallow } from '../../../../components/Block/BlockShallow';
import { PageLayoutContext } from '../../../../context/PageLayoutContext';
import type { ContentfulSysProps } from '../../../../types/contentful';
import { parseMedia } from '../../../../utils/media';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { Highlight } from '../Highlight';
import { VideoPreview } from '../VideoPreview/VideoPreview';
import type { LiveEventProps } from './liveEventQuery';
import {
  blurbClass,
  blurbWithLogoClass,
  highlightsSectionCss,
  highlightsTitleCss,
  learnMoreClass,
  logoCss,
  streamReplayContainerCss,
  streamReplayInnerWrapperCss,
  videoPreviewClass,
} from './PostState.styled';

type Props = Pick<
  LiveEventProps,
  | 'streamPosterImage'
  | 'highlightsCollection'
  | 'highlightsSectionTitle'
  | 'postEventBlocksCollection'
  | 'logo'
  | 'enableBitmojiReactions'
  | 'vodStream'
  | 'postEventOverviewText'
  | 'analyticsId'
  | 'legacyStreamId'
>;

/** Displays the Video-on-Demand (VOD) from the stream. */
export const PostState: FC<Props> = props => {
  const {
    streamPosterImage,
    vodStream,
    highlightsCollection,
    highlightsSectionTitle,
    postEventBlocksCollection,
    logo,
    enableBitmojiReactions,
    postEventOverviewText,
    analyticsId,
    legacyStreamId,
  } = props;
  const { setHeaderBackgroundColorOverride } = useContext(PageLayoutContext);

  // Header background may have been dynamically changed to black if dark mode is enabled.
  // Ensure it is reset to white on the post-state page.
  useImperativeEffect(() => {
    setHeaderBackgroundColorOverride?.(BackgroundColor.White);
  }, [setHeaderBackgroundColorOverride]);

  const { imageSource, imageAltText, videoSource } = parseMedia(logo);

  // Determine whether or not to show isolated VOD view based on if users provides additional content or not (i.e. highlights
  // or post event blocks)
  const shouldShowIsolatedView =
    highlightsCollection.items.length === 0 && postEventBlocksCollection.items.length === 0;

  return (
    <>
      {vodStream && (
        <article
          className={cx(streamReplayContainerCss, {
            isolated: shouldShowIsolatedView,
          })}
        >
          <div className={cx(streamReplayInnerWrapperCss, { isolated: shouldShowIsolatedView })}>
            <VideoPreview
              className={videoPreviewClass}
              // TODO: We're only keeping the event title around to not disrupt analytics collection of the SPS 2024 VOD.
              // Remove this once the SPS 2024 VOD is removed from the web site.
              videoTitle={vodStream?.title}
              videoUrl={vodStream?.streamUrl}
              aslUrl={vodStream?.aslStreamUrl}
              videoBackgroundImage={streamPosterImage}
              captionsCollection={vodStream?.captionsCollection}
              bitmojiProps={{
                enableBitmoji: enableBitmojiReactions,
                // TODO: For SPS 2024, the stream ID was used to tie a bitmoji reaction to a specific stream.
                // Going forward, we will use the analytics ID instead, but we need to ensure that the bitmoji
                // reactions still work for SPS 2024 VOD. Remove this once the SPS 2024 VOD is removed from the web site.
                videoId: legacyStreamId ?? analyticsId,
              }}
              timestampBehavior={vodStream?.timestampBehavior}
              progressOffset={vodStream?.progressOffset}
              analyticsId={analyticsId}
            />
            <section className={blurbWithLogoClass}>
              {videoSource && <video className={logoCss} src={videoSource} autoPlay muted />}
              {imageSource && <img className={logoCss} src={imageSource} alt={imageAltText} />}
              {renderRichText(postEventOverviewText ?? '')}
            </section>
            <p className={blurbClass}>{renderRichText(postEventOverviewText ?? '')}</p>
            {!shouldShowIsolatedView && (
              <img className={learnMoreClass} src="/learn-more.png" alt="Learn More" />
            )}
          </div>
        </article>
      )}
      {highlightsSectionTitle && <h2 className={highlightsTitleCss}>{highlightsSectionTitle}</h2>}
      {highlightsCollection.items.length > 0 && (
        <section className={highlightsSectionCss}>
          {highlightsCollection.items.map(highlightProps => {
            return <Highlight key={highlightProps.sys.id} {...highlightProps} />;
          })}
        </section>
      )}
      {postEventBlocksCollection.items.map((props: ContentfulSysProps, index: number) => {
        return <BlockShallow key={`${props.__typename}_${index}`} {...props} />;
      })}
    </>
  );
};
