import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { Sps2024Registration } from './Sps2024Registration';
import type { Sps2024RegistrationQueryData } from './Sps2024RegistrationQuery';
import { sps2024RegistrationQuery } from './Sps2024RegistrationQuery';

export const Sps2024RegistrationShallow: FC<{ id: string }> = props => {
  const id = props.id;

  const { data } = useContentfulQuery<Sps2024RegistrationQueryData, ContentfulIdVariable>(
    sps2024RegistrationQuery,
    {
      variables: {
        id,
      },
    }
  );

  if (!data) {
    return null;
  }

  return <Sps2024Registration {...data.experienceSps2024Registration} />;
};
