import { cx } from '@emotion/css';
import { useImperativeEffect } from '@snapchat/core-browser';
import { BackgroundColor, MotifScheme } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import type { EventMedia } from '../../../../components/EventPlayer/types';
import { Feature, useFeatureFlags } from '../../../../components/FeatureFlags';
import { PageLayoutContext } from '../../../../context/PageLayoutContext';
import { logUserEvent } from '../../../../helpers/logging/loggingInstance';
import { UserAction } from '../../../../types/events';
import { getRegistrationLocalStorageData } from '../../utils/getRegistrationLocalStorageData';
import type { VideoFrameProps } from '../VideoFrame/VideoFrame';
import { VideoFrame } from '../VideoFrame/VideoFrame';
import type { LiveEventProps } from './liveEventQuery';
import { darkModeCss, liveEventWrapperCss } from './LiveState.styled';
type Props = Pick<
  LiveEventProps,
  | 'streamPosterImage'
  | 'stream'
  | 'backupStream'
  | 'enableDarkMode'
  | 'enableBitmojiReactions'
  | 'bufferStallTimeout'
  | 'analyticsId'
>;

export const LiveState: FC<Props> = props => {
  const {
    streamPosterImage,
    stream,
    backupStream,
    enableBitmojiReactions,
    enableDarkMode,
    bufferStallTimeout,
    analyticsId,
  } = props;

  const features = useFeatureFlags();
  const enableCaptions = features[Feature.EXPERIENCE_ENABLE_CAPTIONS] === 'true';
  const enableAsl = features[Feature.EXPERIENCE_ENABLE_ASL_STREAM] === 'true';

  const { setHeaderBackgroundColorOverride } = useContext(PageLayoutContext);

  const eventMedia: EventMedia = {
    src: stream?.streamUrl ?? '',
    aslSrc: enableAsl ? stream?.aslStreamUrl : undefined,
    backupSrc: backupStream?.streamUrl,
    backupAslSrc: backupStream?.aslStreamUrl,
    poster: streamPosterImage?.url,
    textTracks: enableCaptions
      ? stream?.captionsCollection.items.map(caption => ({
          languageCode: caption.sourceLanguage,
          label: caption.label,
          src: caption.sourceUrl!,
          kind: caption.kind ?? 'subtitles',
        }))
      : undefined,
  };

  const videoFrameProps: VideoFrameProps = {
    eventMedia,
    bitmojiProps: {
      enableBitmoji: enableBitmojiReactions,
      videoId: analyticsId,
    },
    analyticsId,
    isLiveEvent: true,
    timestampBehavior: 'Use Manifest Timestamps',
    bufferStallTimeout,
  };

  // Update header background and log user interests from registration
  useImperativeEffect(() => {
    enableDarkMode && setHeaderBackgroundColorOverride?.(BackgroundColor.Black);
  }, [enableDarkMode, setHeaderBackgroundColorOverride]);

  // Look for any "Interests__c" form values captured in local storage and log them as user interests
  useImperativeEffect(() => {
    const storedRegistrationData = getRegistrationLocalStorageData();

    Object.entries(storedRegistrationData).forEach(([_, registration]) => {
      if (!Array.isArray(registration?.Interests__c)) return;

      registration.Interests__c.forEach(interest =>
        logUserEvent({
          eventAction: UserAction.Open,
          eventCategory: 'LiveEvent',
          eventLabel: `interest: ${interest?.id}`,
        })
      );
    });
  }, []);

  return (
    <div
      className={cx(liveEventWrapperCss, {
        [MotifScheme.SECONDARY]: enableDarkMode,
        [darkModeCss]: enableDarkMode,
      })}
    >
      <VideoFrame {...videoFrameProps} />
    </div>
  );
};
