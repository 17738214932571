import { gql } from '@apollo/client';

import type { TimestampBehavior } from '../../../../components/EventPlayer/types';
import { fragments as mediaFragments } from '../../../../components/Media';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import type { CaptionsData } from '../Captions/captionsQuery';
import { captionsFragment } from '../Captions/captionsQuery';

export const streamFragment = gql`
  fragment StreamAll on Stream {
    ...ContentfulSysId
    title
    streamUrl
    aslStreamUrl
    captionsCollection(limit: 8) {
      items {
        ...CaptionsAll
      }
    }
    timestampBehavior
    progressOffset
    analyticsId
  }
  ${contentfulSysIdFragment}
  ${captionsFragment}
  ${mediaFragments.all}
`;

export interface StreamProps extends ContentfulTypedSysProps<'Stream'> {
  title?: string;
  streamUrl?: string;
  aslStreamUrl?: string;
  captionsCollection: Items<CaptionsData>;
  timestampBehavior?: TimestampBehavior;
  progressOffset?: number;
  bufferStallTimeout?: number;
  analyticsId: string;
}
