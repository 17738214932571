import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { Sps2024Confirmation } from './Sps2024Confirmation';
import { loadingSpacerCss } from './Sps2024Confirmation.styles';
import type { Sps2024ConfirmationQueryData } from './Sps2024ConfirmationQuery';
import { sps2024ConfirmationQuery } from './Sps2024ConfirmationQuery';

export const Sps2024ConfirmationShallow: FC<{ id: string }> = props => {
  const id = props.id;

  const { data } = useContentfulQuery<Sps2024ConfirmationQueryData, ContentfulIdVariable>(
    sps2024ConfirmationQuery,
    {
      variables: {
        id,
      },
    }
  );

  // Return a loading spacer if we don't have data yet so we don't see a flash of below the fold content
  if (!data) return <div className={loadingSpacerCss} />;

  return <Sps2024Confirmation {...data.experienceSps2024Confirmation} />;
};
