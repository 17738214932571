export const bitmojiCanvasWidthPixels = 120;
/** Default height when we don't have access to window.height */
export const defaultBitmojiCanvasHeight = 1200;
/** Default DPI when we don't have window.devicePixelRatio */
export const defaultBitmojiCanvasScale = 2;

/** Virtual pixel width of a rendered bitmoji reaction. */
export const bitmojiWidth = 50;

/** Virtual pixel height of a rendered bitmoji reaction. */
export const bitmojiHeight = 54;

export const getBitmojiUrl = (
  reactionID: string,
  userAvatarID: string,
  widthPx: number
): string => {
  const url = new URL('https://sdk.bitmoji.com');
  url.pathname = `/render/panel/${reactionID}-${userAvatarID}-v2.png`;
  url.searchParams.set('transparent', '1');
  url.searchParams.set('palette', '1');
  url.searchParams.set('feature', 'SPS');
  url.searchParams.set('scale', '2');
  url.searchParams.set('width', widthPx.toFixed(0));
  url.searchParams.set('scale', '2');
  return url.href;
};
