export enum BackgroundType {
  DarkMode = 'Black',
  LightMode = 'White',
}

/** Display options for a category of cookies. Used to support conditional rendering. */
export enum CategoryDisplayMode {
  Normal = 'Normal',
  SettingsOnly = 'Settings Only',
}

/** Screens of the Cookie Modal. */
export enum CookieScreen {
  LANDING = 'landing',
  SETTINGS = 'settings',
}

/**
 * Cookies used to track user opt-in to various categories of cookies. We don't need to track all
 * values, just the ones where we have special logic.
 */
export enum CategoryOptInCookie {
  Essential = 'sc-cookies-accepted',
  EssentialSession = 'EssentialSession',
  Marketing = 'Marketing',
  Performance = 'Performance',
  Preferences = 'Preferences',
}

/** State for whether cookie categories have been accepted. */
export type CookieAcceptance = Partial<Record<CategoryOptInCookie, boolean>>;

/**
 * Interface for locale options. Note that doesn't use contentful's Locale to support sites that do
 * not store data in contentful.
 */
export interface LocaleOption {
  /** Locale Code */
  code: string;
  /** Display Text for the Locale */
  name: string;
}

/** Interface for actions for logged user events */
export enum EventAction {
  Click = 'Click',
}
