import type { ReactionNotification } from '@snapchat/mw-common';

import { customFetch } from '../../../../utils/fetch/customFetch';
import { brsUrl } from '../../components/BitmojiControls/constants';

export type ReactionBucket = {
  videoTime: number;
  reactions: ReactionNotification[];
};

export const getReactionReplays = async (
  videoId: string,
  startTime: string,
  endTime: string
): Promise<ReactionBucket[]> => {
  const options: RequestInit | undefined = brsUrl.includes('gae.sc-corp.net')
    ? { credentials: 'include' } // needed to communicate with secproxy.
    : undefined;
  const replaysResp = await customFetch(
    `${brsUrl}/bitmoji/api/replayReactions?${new URLSearchParams({
      videoId,
      startTime,
      endTime,
    })}`,
    options
  );

  return await replaysResp.json();
};
