/* eslint-disable max-len */
export const generateSps2024Data = (textLines: string[]): object => {
  // NOTE: For text replacement make sure you requested dynamic text from designers.
  // You may need to delete the "chars" field in the json if some chacters do not render
  // see https://github.com/airbnb/lottie-android/issues/2324
  const [line1 = '', line2 = '', line3 = '', line4 = ''] = textLines;
  return {
    v: '4.8.0',
    meta: { g: 'LottieFiles AE 3.5.3', a: '', k: '', d: '', tc: '' },
    fr: 30,
    ip: 0,
    op: 120,
    w: 1080,
    h: 540,
    nm: '**MASTER - ORIGINAL 30FPS - LOTTIE',
    ddd: 0,
    assets: [],
    fonts: {
      list: [
        {
          fName: 'Graphik-Medium',
          fFamily: 'Graphik',
          fStyle: 'Medium',
          ascent: 76.4999389648438,
        },
      ],
    },
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 5,
        nm: 'SNAP',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.483], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 22,
                s: [0],
              },
              { t: 37.2001953125, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.153], y: [1] },
                  o: { x: [0.017], y: [0] },
                  t: 22,
                  s: [880],
                },
                { t: 99, s: [704.369] },
              ],
              ix: 3,
            },
            y: { a: 0, k: 203.5, ix: 4 },
          },
          a: { a: 0, k: [-97.125, -35.5, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  sz: [482, 50.5],
                  ps: [-241, -86],
                  s: 64,
                  f: 'Graphik-Medium',
                  t: line1,
                  j: 0,
                  tr: 5,
                  lh: 76.8,
                  ls: 0,
                  fc: [0, 0, 0],
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
          a: [],
        },
        ip: 22,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 5,
        nm: 'PARTNER',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.483], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 30,
                s: [0],
              },
              { t: 46, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.153], y: [1] },
                  o: { x: [0.011], y: [0] },
                  t: 30,
                  s: [880],
                },
                { t: 99, s: [704.369] },
              ],
              ix: 3,
            },
            y: { a: 0, k: 265.5, ix: 4 },
          },
          a: { a: 0, k: [-97.125, -35.5, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  sz: [482, 53],
                  ps: [-241, -86],
                  s: 64,
                  f: 'Graphik-Medium',
                  t: line2,
                  j: 0,
                  tr: 5,
                  lh: 76.8,
                  ls: 0,
                  fc: [0, 0, 0],
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
          a: [],
        },
        ip: 30,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 5,
        nm: 'SUMMIT',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.483], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 36,
                s: [0],
              },
              { t: 52, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.153], y: [1] },
                  o: { x: [0.007], y: [0] },
                  t: 36,
                  s: [880],
                },
                { t: 99.0009765625, s: [704.369] },
              ],
              ix: 3,
            },
            y: { a: 0, k: 330.5, ix: 4 },
          },
          a: { a: 0, k: [-97.125, -35.5, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  sz: [482, 53],
                  ps: [-241, -86],
                  s: 64,
                  f: 'Graphik-Medium',
                  t: line3,
                  j: 0,
                  tr: 5,
                  lh: 76.8,
                  ls: 0,
                  fc: [0, 0, 0],
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
          a: [],
        },
        ip: 36,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 5,
        nm: '2024',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.483], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 41,
                s: [0],
              },
              { t: 58, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.153], y: [1] },
                  o: { x: [0.006], y: [0] },
                  t: 41,
                  s: [880],
                },
                { t: 99, s: [704.369] },
              ],
              ix: 3,
            },
            y: { a: 0, k: 393.5, ix: 4 },
          },
          a: { a: 0, k: [-97.125, -35.5, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  sz: [482, 52],
                  ps: [-241, -86],
                  s: 64,
                  f: 'Graphik-Medium',
                  t: line4,
                  j: 0,
                  tr: 5,
                  lh: 76.8,
                  ls: 0,
                  fc: [0, 0, 0],
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
          a: [],
        },
        ip: 41,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 3,
        nm: 'Null 1',
        sr: 1,
        ks: {
          o: { a: 0, k: 0, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.568], y: [0.837] },
                  o: { x: [0.124], y: [0.198] },
                  t: 0,
                  s: [720],
                },
                {
                  i: { x: [0.587], y: [1] },
                  o: { x: [0.311], y: [0.972] },
                  t: 41,
                  s: [409.777],
                },
                { t: 90.0001953125, s: [374] },
              ],
              ix: 3,
            },
            y: { a: 0, k: 271, ix: 4 },
          },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        ip: 0,
        op: 120,
        st: -7.2,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: 'Mask',
        parent: 5,
        td: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.341], y: [0.925] },
                  o: { x: [0], y: [0] },
                  t: 0,
                  s: [-320],
                },
                {
                  i: { x: [0.655], y: [1] },
                  o: { x: [0.322], y: [2.276] },
                  t: 48,
                  s: [-12.353],
                },
                { t: 90, s: [-8] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.341], y: [0.925] },
                  o: { x: [0], y: [0] },
                  t: 0,
                  s: [216],
                },
                {
                  i: { x: [0.655], y: [1] },
                  o: { x: [0.322], y: [2.276] },
                  t: 48,
                  s: [9.916],
                },
                { t: 90, s: [7] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-174.468, 7.974, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.341, 0.341, 0.63], y: [0.925, 0.925, 0.984] },
                o: { x: [0, 0, 0.168], y: [0, 0, -0.08] },
                t: 0,
                s: [113, 113, 100],
              },
              {
                i: { x: [0.655, 0.655, 0.684], y: [1, 1, 1] },
                o: { x: [0.322, 0.322, 0.348], y: [2.276, 2.276, 0.013] },
                t: 48,
                s: [100.181, 100.181, 100],
              },
              { t: 90, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [77.265, 0],
                      [0, -77.265],
                      [-77.265, 0],
                      [0, 77.265],
                    ],
                    o: [
                      [-77.265, 0],
                      [0, 77.265],
                      [77.265, 0],
                      [0, -77.265],
                    ],
                    v: [
                      [3.637, -139.4],
                      [-136.263, 0.5],
                      [3.637, 140.4],
                      [143.537, 0.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-178.03, 7.897], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [89.022, 89.022], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: 'Main Circle - FULL',
        parent: 5,
        tt: 2,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: { a: 0, k: 0, ix: 3 },
            y: { a: 0, k: 0, ix: 4 },
          },
          a: { a: 0, k: [-166, 1, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [77.265, 0],
                      [0, -77.265],
                      [-77.265, 0],
                      [0, 77.265],
                    ],
                    o: [
                      [-77.265, 0],
                      [0, 77.265],
                      [77.265, 0],
                      [0, -77.265],
                    ],
                    v: [
                      [3.637, -139.4],
                      [-136.263, 0.5],
                      [3.637, 140.4],
                      [143.537, 0.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.98431372549, 0, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-170, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 120,
        st: -7.2,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 1,
        nm: 'BG',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: { a: 0, k: 540, ix: 3 },
            y: { a: 0, k: 270, ix: 4 },
          },
          a: { a: 0, k: [960, 540, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        sw: 1920,
        sh: 1080,
        sc: '#ffffff',
        ip: 0,
        op: 120,
        st: 0,
        bm: 0,
      },
    ],
    markers: [{ tm: 90, cm: '1', dr: 0 }],
  };
};
