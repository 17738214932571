import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

const fragment = gql`
  fragment CventAuthenticatorAll on CventAuthenticator {
    eventId
    unauthenticatedSlug {
      ...ContentfulSysId
      slug
    }
    ...ContentfulSysId
  }
  ${contentfulSysIdFragment}
`;

export const cventAuthenticatorQuery = gql`
  query CventAuthenticatorQuery($preview: Boolean!, $locale: String!, $id: String!) {
    cventAuthenticator(preview: $preview, locale: $locale, id: $id) {
      ...CventAuthenticatorAll
    }
  }
  ${fragment}
`;

export interface CventAuthenticatorQueryProps {
  cventAuthenticator: CventAuthenticatorDataProps;
}

export interface CventAuthenticatorDataProps extends ContentfulTypedSysProps<'CventAuthenticator'> {
  eventId: string;
  unauthenticatedSlug: {
    slug: string;
  };
}
