/**
 * Values for SubscribedEventType.USER_INTERACTION event.eventAction field.
 *
 * For backwards compatibility we allow other values to be used, but going forward we should limit
 * ourselves to this list.
 *
 * These should be generic and indicate a singular user action, like
 * Click/Accept/Pause/Dismiss/Scroll/Open etc
 *
 * NOTE= These are for USER actions, and are not appropriate to be used for internal or timing
 * events!
 *
 * TODO: This needs to have CAPITAL letters in the name. We should rename.
 */
export const enum UserAction {
  Click = 'Click',
  Close = 'Close',
  Enter = 'Enter',
  ExitFullscreen = 'ExitFullscreen',
  FormSubmit = 'FormSubmit',
  Fullscreen = 'Fullscreen',
  HideCaptions = 'HideCaptions',
  Leave = 'Leave',
  Mute = 'Mute',
  Open = 'Open',
  Pause = 'Pause',
  Play = 'Play',
  Scroll = 'Scroll',
  Seek = 'Seek',
  SetVolume = 'Volume',
  ShowCaptions = 'ShowCaptions',
  Unmute = 'Unmute',
  Warning = 'Warning',
}
