import type { FC } from 'react';

import { BlockShallow } from '../../../../components/Block/BlockShallow';
import { HeroShallow } from '../../../../components/Hero/HeroShallow';
import { logError } from '../../../../helpers/logging';
import type { ContentfulSysProps } from '../../../../types/contentful';
import { Sps2024RegistrationShallow } from '../Sps2024Registration/Sps2024RegistrationShallow';
import type { LiveEventProps } from './liveEventQuery';

type Props = Pick<LiveEventProps, 'preEventBlocksCollection'>;

/**
 * There's no concrete pre-event state and it varies year-by-year. For this reason prevent state can
 * be any block.
 */
export const PreState: FC<Props> = props => {
  const { preEventBlocksCollection } = props;

  return (
    <>
      {preEventBlocksCollection.items.map((props: ContentfulSysProps, index: number) => {
        switch (props.__typename) {
          case 'Block': {
            return <BlockShallow key={`${props.__typename}_${index}`} {...props} />;
          }

          case 'BlockHero': {
            return <HeroShallow key={`${props.__typename}_${index}`} {...props} />;
          }

          case 'ExperienceSps2024Registration': {
            return (
              <Sps2024RegistrationShallow key={`${props.__typename}_${index}`} id={props.sys.id} />
            );
          }

          default: {
            logError({
              component: 'LiveEvent',
              error: `Component not supported: "${props.__typename}"`,
            });

            return null;
          }
        }
      })}
    </>
  );
};
