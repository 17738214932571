import { css, cx } from '@emotion/css';
import { useImperativeEffect } from '@snapchat/core-browser';
import type { LottiePlayer } from 'lottie-web/build/player/lottie_light';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { logError } from '../../../../helpers/logging';
import { generateSps2024Data } from './generate2024AnimationData';

type Sps2024AnimationProps = {
  className?: string;
  textLines: string[];
};

const animationContainerCss = css`
  /* To capture inner-animation attachment */
  position: relative;

  z-index: 0;

  /* Prevents the height from collapsing. */
  min-height: 215px;
`;

const animationInnerCss = css`
  /* To make it not animate over other container. Position required for z-index effect. */
  position: absolute;
  z-index: 0;

  /* Zoom in. Based on the % of container's width. Tuned to fit "REGISTERING," text. */
  width: 173%;

  /* Offset also has to be based on the same thing as width. */
  left: -38%;
  top: 0;
`;

const spacerCss = css`
  /* Forces the height to be at least 60% of the width. */
  padding-top: 60%;
`;

const animationName = 'spsAnimation2024';

export const Sps2024Animation: FC<Sps2024AnimationProps> = ({
  className,
  textLines,
  ...others
}) => {
  const [spsAnimationDiv, setSpsAnimationDiv] = useState<HTMLDivElement | null>(null);
  const setAnimationContainer = useCallback(
    (node: HTMLDivElement): void => setSpsAnimationDiv(node),
    []
  );

  const [lottie, setLottie] = useState<LottiePlayer | undefined>();

  // Lottie breaks on SSR due to a known bug: https://github.com/Gamote/lottie-react/issues/101
  // Workaround: Load Lottie dynamically on the client side before using it
  useImperativeEffect(() => {
    import('lottie-web/build/player/lottie_light')
      .then(({ default: dynamicLottie }) => {
        setLottie(dynamicLottie);
      })
      .catch(error => {
        logError({
          component: 'Sps2024Animation',
          message: 'Failed to load Lottie module',
          error,
        });
      });
  }, []);

  useEffect(() => {
    if (!spsAnimationDiv || !lottie) return;

    lottie.loadAnimation({
      name: animationName,
      container: spsAnimationDiv, // the dom element that will contain the animation: ;
      // See: https://github.com/airbnb/lottie-web/wiki/Renderer-Settings
      rendererSettings: {
        // Placeholder to crop whitespace
        // viewBoxSize: '0 0 1000 500',
        // viewBoxOnly: true,
      },
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: generateSps2024Data(textLines), // the path to the animation json
    });

    return () => lottie?.destroy(animationName);
  }, [lottie, spsAnimationDiv, textLines]);

  return (
    <figure
      data-testid="sps-animation-wrapper"
      className={cx(animationContainerCss, className)}
      {...others}
    >
      <div
        data-testid="sps-animation-canvas"
        ref={setAnimationContainer}
        className={cx(animationInnerCss, 'sps-animation-canvas')}
      />
      <div data-testid="sps-animation-spacer" className={spacerCss} />
    </figure>
  );
};
