import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const captionsFragment = gql`
  fragment CaptionsAll on Caption {
    ...ContentfulSysId
    kind
    sourceLanguage
    sourceUrl
    label
  }
  ${contentfulSysIdFragment}
`;

export interface CaptionsData extends ContentfulTypedSysProps<'Captions'> {
  kind: 'subtitles' | 'captions';
  sourceLanguage: string;
  sourceUrl?: string;
  label?: string;
}
