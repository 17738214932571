import { css } from '@emotion/css';

// TODO: Find a way to cleanly show bitmoji reactions in mobile
export const bitmojiCanvasCss = css`
  position: absolute;
  top: 0;
  /** Aligns with the sibling. */
  left: auto;
  height: 100vh;
  height: 100dvh;
  opacity: 1;
  transform: translateX(-100%);
  transition: all 0.2s ease-out;

  .hide-bitmoji & {
    opacity: 0;
  }
`;
