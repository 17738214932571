import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../../utils/contentful/contentfulQuery';

export const salesforceConnectorFragment = gql`
  fragment SalesforceConnectorAll on SalesforceConnector {
    category
    year
    confirmationEmail {
      ...ContentfulSysId
    }
    ...ContentfulSysId
  }
  ${contentfulSysIdFragment}
`;

export const cventConnectorFragment = gql`
  fragment CventConnectorAll on CventConnector {
    eventId
    admissionItemId
    invitationListId
    allowlistErrorText {
      json
    }
    ...ContentfulSysId
  }
  ${contentfulSysIdFragment}
`;
