import { css } from '@emotion/css';
import {
  mobileMediaQuery,
  nonMobileMediaQuery,
  Spacing,
} from '@snapchat/snap-design-system-marketing';

const thumbnailBorderRadiusPx = 10;

export const videoPreviewImageContainerCss = css`
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: 50.625vw;

  &:after {
    content: '';
    cursor: pointer;
    opacity: 0.2;
    background: black;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${nonMobileMediaQuery} {
    padding-bottom: 31%;
    margin-top: 1em;
    height: 100%;

    &:hover {
      &:after {
        opacity: 0.4;
      }
    }
  }
`;

export const videoPreviewPlayButtonCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
  width: 40px;

  ${nonMobileMediaQuery} {
    width: auto;
  }
`;

export const videoPreviewImageTimerCss = css`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: black;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 6px;
  z-index: 1;
  border-radius: ${thumbnailBorderRadiusPx}px;
`;

export const videoPreviewBackgroundImgCss = css`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${thumbnailBorderRadiusPx}px;
`;

export const videoPreviewModalCss = css`
  .sds-modal-close-btn {
    /* This value gives the best result across screen sizes so that it doesn't overlap with the video */
    right: -68px;

    ${mobileMediaQuery} and (orientation: portrait) {
      right: -${Spacing['3XL']}px;
    }
  }
`;
