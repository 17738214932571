import { gql } from '@apollo/client';
import type { Alignment, BackgroundColor } from '@snapchat/snap-design-system-marketing';

import { fragments as analyticsFragments } from '../../../../components/Analytics/query';
import type { AnalyticsDataProps } from '../../../../components/Analytics/types';
import type { ContentDataProps } from '../../../../components/Content';
import { fragments as contentFragments } from '../../../../components/Content';
import type { ContentfulFormRow } from '../../../../components/Form';
import { formRowFragment } from '../../../../components/Form';
import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import type { RichText } from '../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import { cventConnectorFragment, salesforceConnectorFragment } from '../fragments';
import type { CventConnectorDataProps, SalesforceConnectorDataProps } from '../types';

export const fragments = {
  all: gql`
    fragment RegistrationFormBlockAll on RegistrationFormBlock {
      ...ContentfulSysId
      eyebrow
      title {
        json
      }
      subtitle {
        json
      }
      titleAlignmentDesktop
      titleAlignmentMobile
      backgroundColor
      fullHeight
      formRowsCollection {
        items {
          ...FormRowAll
        }
      }
      formAnalytics {
        ...AnalyticsAll
      }
      connector {
        ... on SalesforceConnector {
          ...SalesforceConnectorAll
        }
        ... on CventConnector {
          ...CventConnectorAll
        }
      }
      confirmationSlug {
        ...ContentfulSysId
        slug
      }
      rememberRegistration
      unknownErrorText {
        json
      }
      sideContent {
        ...ContentAll
      }
    }
    ${formRowFragment}
    ${contentFragments.all}
    ${analyticsFragments.all}
    ${salesforceConnectorFragment}
    ${cventConnectorFragment}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query RegistrationBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    registrationFormBlock(preview: $preview, locale: $locale, id: $id) {
      ...RegistrationFormBlockAll
    }
  }
  ${fragments.all}
`;

export interface RegistrationBlockData extends ContentfulTypedSysProps<'RegistrationBlock'> {
  registrationFormBlock: {
    eyebrow?: string;
    title?: RichText;
    subtitle?: RichText;
    titleAlignmentDesktop?: Alignment;
    titleAlignmentMobile?: Alignment;
    backgroundColor?: BackgroundColor;
    fullHeight?: boolean;
    formRowsCollection?: Items<ContentfulFormRow>;
    formAnalytics?: AnalyticsDataProps;
    connector?: CventConnectorDataProps | SalesforceConnectorDataProps;
    confirmationSlug?: { slug: string };
    rememberRegistration?: boolean;
    unknownErrorText?: RichText;
    sideContent?: ContentDataProps;
  };
}
