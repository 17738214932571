import gql from 'graphql-tag';

import { ctaFragment } from '../../../../components/CallToAction';
import { fragments as mediaFragments } from '../../../../components/Media';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import { captionsFragment } from '../Captions/captionsQuery';

export const hightlightFragment = gql`
  fragment HighlightAll on Highlight {
    ...ContentfulSysId
    title
    name
    blurb {
      json
    }
    videoBackgroundImage {
      ...AssetAll
    }
    videoLength
    videoUrl
    aslUrl
    captionsCollection(limit: 8) {
      items {
        ...CaptionsAll
      }
    }
    callToActionsCollection(limit: 2) {
      items {
        ...CallToActionAll
      }
    }
    analyticsId
  }
  ${contentfulSysIdFragment}
  ${captionsFragment}
  ${ctaFragment}
  ${mediaFragments.all}
`;

export const highlightsFragment = gql`
  fragment HighlightsAll on Highlights {
    ...ContentfulSysId
    title
    mainHighlight {
      ...HighlightAll
    }
    highlightsCollection(limit: 16) {
      items {
        ...HighlightAll
      }
    }
  }
  ${contentfulSysIdFragment}
  ${hightlightFragment}
`;

export const highlightQuery = gql`
  query Highlights($id: String!, $locale: String!, $preview: Boolean!) {
    highlights(id: $id, locale: $locale, preview: $preview) {
      ...HighlightsAll
    }
  }
  ${highlightsFragment}
`;
