import type { FC, MutableRefObject, PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useRef } from 'react';

import { logError } from '../../../../helpers/logging';
import {
  bitmojiCanvasWidthPixels,
  bitmojiWidth,
  defaultBitmojiCanvasScale,
  getBitmojiUrl,
} from '../BitmojiCanvas/constants';

export interface BitmojiStreamItem {
  image: HTMLImageElement;
  x: number;
  y: number;
}

export const BitmojiContext = createContext<BitmojiContextProps>({});

export const invalidImgUrl = '/images/404.png';

interface BitmojiContextProps {
  bitmojiStreamRef?: MutableRefObject<BitmojiStreamItem[]>;
  canvasRef?: MutableRefObject<HTMLCanvasElement | null>;
  sendToBitmojiStream?: (reactionID: string, userAvatarID: string) => void;
  updateVideoPaused?: (isPaused: boolean) => void;
}

export const BitmojiContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const bitmojiStreamRef = useRef<BitmojiStreamItem[]>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isPausedRef = useRef<boolean>(false);
  const isVisibleRef = useRef<boolean>(true);

  useEffect(() => {
    const visibilityChange = () => {
      isVisibleRef.current = document.visibilityState === 'visible';
    };

    document.addEventListener('visibilitychange', visibilityChange);
    return () => document.removeEventListener('visibilitychange', visibilityChange);
  }, []);

  const updateVideoPaused = useCallback((isPaused: boolean) => {
    isPausedRef.current = isPaused;
  }, []);

  const sendToBitmojiStream = useCallback((reactionID: string, userAvatarID: string) => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    // Do not render the bitmoji if video is paused or page isn't visible, prevents clustering
    if (!isVisibleRef.current || isPausedRef.current) {
      return;
    }

    const dpr = window.devicePixelRatio || defaultBitmojiCanvasScale;

    const imgTag = new Image();

    // Handle when image has 404
    imgTag.addEventListener(
      'error',
      () => {
        logError({
          component: 'BitmojiCanvas',
          message: 'Could not load reaction image.',
        });
        imgTag.src = invalidImgUrl;
      },
      // Only invoke it once, then remove the event listener
      { once: true }
    );

    imgTag.src = getBitmojiUrl(reactionID, userAvatarID, dpr * bitmojiWidth); // load image

    const newBitmoji = {
      image: imgTag,

      // Upper bound for x coordinate is total width of the canvas minus the width
      // of the bitmoji so that the bitmoji is never cut off by the right edge of the canvas
      x: Math.floor(Math.random() * (bitmojiCanvasWidthPixels - bitmojiWidth)),
      y: canvas.height,
    };

    bitmojiStreamRef.current.unshift(newBitmoji);
  }, []);

  return (
    <BitmojiContext.Provider
      value={{
        bitmojiStreamRef,
        canvasRef,
        sendToBitmojiStream,
        updateVideoPaused,
      }}
    >
      {children}
    </BitmojiContext.Provider>
  );
};
