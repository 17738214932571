import { css } from '@emotion/css';
import { nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const highlightCss = css`
  margin-bottom: 30px;

  ${nonMobileMediaQuery} {
    display: flex;

    > section {
      flex-basis: 50%;
      flex-grow: 0;
    }
  }
`;

export const highlightContentCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  .stream-replay & {
    display: none;
    ${nonMobileMediaQuery} {
      padding-left: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  ${nonMobileMediaQuery} {
    padding-left: 40px;
  }
`;

export const highlightContentTitleCss = css`
  margin: 12px 0 10px;
`;

export const highlightContentDescriptionCss = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;

  ${nonMobileMediaQuery} {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-top: 15px;
    min-height: 50px;
  }
`;

export const highlightButtonsCss = css`
  display: block;
  margin-top: 10px;

  > a {
    display: inline-block;
    margin-top: 10px;
  }

  > a:first-child {
    margin-right: 15px;
  }

  ${nonMobileMediaQuery} {
    margin-top: 30px;
  }
`;
