import { cx } from '@emotion/css';
import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';
import { experienceHeaderCss } from './styles/experienceHeader.styles';

export const config: SiteConfiguration = {
  domainName: 'experience.snap.com',

  globalNavProps: {
    showGlobalLinks: true,
    className: cx(experienceHeaderCss),
  },

  headerProps: {
    className: cx(experienceHeaderCss),
  },

  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.EXPERIENCE },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleAnalyticsId: 'G-KQ0NHS593H',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'experience',
        dsn: 'https://4f860f15f13d4d88b16fba551bf00d83@sentry.sc-prod.net/232',
      },
    ],
  },
  redirects: {
    hostRedirects: {},
  },

  csp: {
    mediaSrc: [
      // MWP AWS Live Streams served via Cloudfront (us-east-1)
      'https://d3tjmrgpkoe5uy.cloudfront.net',
      'https://d3evri4z1bno4v.cloudfront.net',
      'https://d1mjs355if1lso.cloudfront.net',
      'https://dw7bpo19jtay9.cloudfront.net',

      // MWP AWS Live Streams served via Cloudfront (us-west-2)
      'https://dtyw137ywmvuz.cloudfront.net',
      'https://d2w7e30aqreiq5.cloudfront.net',
      'https://d2ppmn40l4ys0v.cloudfront.net',
      'https://d9p20otdab72v.cloudfront.net',

      // MWP AWS VODs served via Cloudfront
      'https://d2c0et4w5flcyu.cloudfront.net',
    ],
    connectSrc: [
      // MWP AWS Live Streams served via Cloudfront (us-east-1)
      'https://d3tjmrgpkoe5uy.cloudfront.net',
      'https://d3evri4z1bno4v.cloudfront.net',
      'https://d1mjs355if1lso.cloudfront.net',
      'https://dw7bpo19jtay9.cloudfront.net',

      // MWP AWS Live Streams served via Cloudfront (us-west-2)
      'https://dtyw137ywmvuz.cloudfront.net',
      'https://d2w7e30aqreiq5.cloudfront.net',
      'https://d2ppmn40l4ys0v.cloudfront.net',
      'https://d9p20otdab72v.cloudfront.net',

      // MWP AWS VODs served via Cloudfront
      'https://d2c0et4w5flcyu.cloudfront.net',

      // Bitmovin video analytics integration
      'https://analytics-ingress-global.bitmovin.com',
    ],
  },
};
