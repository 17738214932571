import { css } from '@emotion/css';
import { m, mobileMediaQuery, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const spsLogoCharCountCssVar = '--sps-logo-char-count';
export const usernameFormTextClassName = 'username-form-text';
export const middleTextClassName = 'event-info';
export const eventInfoTextClassName = 'event-info-text';
export const saveTheDateClassName = 'save-the-date';
export const primaryTextClassName = 'primary-text';

export const registrationResultContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; /* To center-align the text */

  > * {
    max-width: 634px;
  }

  ${mobileMediaQuery} {
    display: inline;

    justify-content: left;
    align-items: left;
    text-align: left;

    > * {
      padding: 32px;
      position: relative; /* to get zindex to work smh */
      max-width: unset;
    }
    overflow: hidden; /* to not let the animation cause the page to need horizontal scroll. */
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
  }

  .${primaryTextClassName} {
    padding: 0 32px;
    font-size: 20px;
    line-height: 28px;

    a {
      font-size: 20px;
    }

    ${mobileMediaQuery} {
      font-size: 16px;
      padding-top: 0;
      a {
        font-size: 16px;
      }
    }
  }

  .${usernameFormTextClassName} {
    padding-block: 0 ${m('--spacing-l')};
    text-align: center;

    ${mobileMediaQuery} {
      padding-block: 0;
      text-align: start;
    }
  }

  &&& {
    h1 {
      font-size: 28px;
      font-weight: 600;
      line-height: 34px;
      padding-bottom: 24px;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      padding-bottom: 8px;
    }
    ${mobileMediaQuery} {
      h1 {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .${middleTextClassName} {
    line-height: 26px;
    ${nonMobileMediaQuery} {
      padding-top: 48px;
    }

    a {
      font-size: 20px;
    }
  }

  .${saveTheDateClassName} {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  /* Make everything that's not the animation render in front, so that the animation does not paint over it. */
  & > *:not(figure) {
    z-index: 1;
  }
`;

export const ctaCss = css`
  margin: 0 32px 32px 32px;
  ${nonMobileMediaQuery} {
    margin-bottom: 124px;
  }
`;

/**
 * Used to offset the logo alignment by an amount based on the character count of the longest line
 * (based on current text versions)
 *
 * NOTES: value is very fragile, dependent on animation file at time of writing and styling of the
 * registration page with % based logo alignment. must be recalculated if the animation file changes
 * (particularly font size, style, positioning). value calculated by:
 *
 * - Calculating the difference between longest and shortest text variations
 * - When its longest (12 char "REGISTERING,") the ideal is -49%
 * - When its shortest (7 char "PARTNER") the ideal is -37%
 * - 12% * (12-charcount)/7 - 49% is the equation to get them
 */
const characterOffsetPercentage = 12;
const baseOffset = 49;
const longestCharCount = 12;
const charDiff = 5;

export const registrationResultAnimationCss = css`
  width: 600px;
  margin-top: -65px;

  ${nonMobileMediaQuery} {
    /* Center the logo by calculating offset by longest line of text */
    .sps-animation-canvas {
      left: calc(
        ${characterOffsetPercentage}% * (${longestCharCount} - var(${spsLogoCharCountCssVar})) /
          ${charDiff} - ${baseOffset}%
      );
    }
    padding-bottom: 64px;
  }

  ${mobileMediaQuery} {
    margin-top: 16px;
    margin-left: 32px;
    width: calc(100vw - 64px * 2 * var(${spsLogoCharCountCssVar}) / ${longestCharCount});
  }
`;

export const regAddCalendarCss = css`
  list-style: none;
  margin-bottom: 30px;
  li {
    display: inline-block;
    img {
      width: 50px;
    }
  }
  li:not(:first-child) {
    margin-left: 44px;
  }
`;

export const topPaddingCss = css`
  margin-top: 32px;
`;

export const loadingSpacerCss = css`
  height: 100vh;
  height: 100dvh;
`;
