import { css } from '@emotion/css';
import { mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

import { bitmojiCanvasWidthPixels } from '../BitmojiCanvas/constants';
export const hideOnMobileCss = css`
  ${mobileMediaQuery} {
    display: none;
  }
`;

export const videoContainerCss = css`
  /* Not perfect, but 135vh minus some buffer prevents the player from
     expanding into the header on most screen sizes
     TODO: Find a cleaner way to accomplish this (maybe aspect-ratio property) */
  max-width: calc(135vh - 50px);

  /* Leave enough  margin for bitmoji reactions */
  width: calc(100vw - ${bitmojiCanvasWidthPixels * 2}px);

  min-width: 300px;
  margin: 0 auto;
  position: relative;
  bottom: 10px;

  ${mobileMediaQuery} {
    width: 90vw;

    .sds-modal-content & {
      bottom: 0px;
    }
  }
`;

export const videoPositionCss = css`
  padding-top: 56%;
`;
