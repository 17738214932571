import { Spinner } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { eventPlayerLoadingSpinnerCss } from './EventPlayer.styles';

/**
 * Loading indicator for the EventPlayer component. Meant to show during initial load of player as
 * well as when buffering.
 */
export const EventPlayerLoadingSpinner: FC = () => (
  <Spinner className={eventPlayerLoadingSpinnerCss} size={75} />
);
